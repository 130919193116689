<template>
  <v-menu
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
    bottom
    origin="center center"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <a class="filtro" color="#000" dark v-on="on">
        <v-icon left color="primary">mdi-filter-variant</v-icon>
        <span style="color: #000">{{ $t("filter") }}</span>
      </a>
    </template>

    <v-card class="px-3 py-3">
      <!-- <pre>
        {{ vacancy }}
      </pre> -->
      <v-container fluid>
        <v-card flat max-width="550">
          <v-row>
            <v-col cols="12" md="12" v-if="!database">
              <v-autocomplete
                v-model="vacancy"
                :items="!searchV ? vacancies : allVacancies"
                item-text="title"
                :search-input.sync="searchV"
                :loading="loading"
                cache-items
                hide-no-data
                item-value="id"
                outlined
                :label="$t('vacancy')"
                dense
                hide-details
                :filter="onFilter"
                @change="
                  $emit('change', vacancy);
                  change();
                "
                @click="$emit('change', vacancy)"
              ></v-autocomplete>
            </v-col>

            <v-col class="12" md="12">
              <template v-for="(formation, index) in filter.formation">
                <v-row :key="`${index}formation`">
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="formation.course"
                      :items="getFormation"
                      item-text="text"
                      item-value="key"
                      :label="$t('academicEdu')"
                      outlined
                      dense
                      hide-details
                      @change="apply()"
                      :filter="onFilter"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-autocomplete
                      v-model="formation.level"
                      :items="academicLevel"
                      item-text="text"
                      item-value="key"
                      :label="$t('form_candidate_academic_level')"
                      outlined
                      dense
                      @change="apply()"
                      hide-details
                      :filter="onFilter"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="1" class="text-center mt-1">
                    <v-btn
                      x-small
                      outlined
                      fab
                      color="secondary"
                      @click="
                        index === 0 ? addFormation() : removeFormation(index)
                      "
                    >
                      <!-- <v-icon small>mdi-plus</v-icon> -->
                      <v-icon v-if="index === 0" small>mdi-plus</v-icon>
                      <v-icon v-else small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="filter.areasInterest"
                :items="getAreas"
                item-text="text"
                item-value="key"
                outlined
                :label="$t('form_candidate_inter')"
                dense
                multiple
                chips
                hide-details
                @change="changedField"
                :filter="onFilter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="filter.province"
                :items="getProvince"
                item-text="text"
                item-value="key"
                :label="$t('province')"
                outlined
                dense
                hide-details
                :filter="onFilter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.number="filter.professionalExperience"
                :label="$t('professionalExperience')"
                type="number"
                outlined
                dense
                hide-details
                @input="changedFieldSingle('professionalExperience')"
              ></v-text-field>
            </v-col>
            <v-col :cols="6" :md="6">
              <v-autocomplete
                v-model="filter.sector"
                :items="sector"
                item-text="text"
                item-value="key"
                :label="$t('select_sector', $i18n.locale)"
                dense
                outlined
                hide-details
                @change="apply()"
                :filter="onFilter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
              <v-autocomplete
                v-model="filter.otherSkills"
                :label="$t('form_candidate_tools')"
                :items="getSkill"
                multiple
                outlined
                dense
                chips
                hide-details
                @input="changedField"
                :filter="onFilter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
              <template v-for="(lang, index) in filter.languages">
                <v-row :key="`${index}languages`">
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="lang.name"
                      :items="languages"
                      :item-text="$t('text')"
                      item-value="key"
                      outlined
                      :label="$t('language')"
                      dense
                      chips
                      hide-details
                      @change="changedField"
                      :filter="onFilter"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-autocomplete
                      v-model="lang.level"
                      :items="levelLanguages"
                      :item-text="$t('text')"
                      item-value="key"
                      :label="$t('level')"
                      outlined
                      dense
                      hide-details
                      @change="changedField"
                      :filter="onFilter"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="1" class="text-center mt-1">
                    <v-btn
                      x-small
                      outlined
                      fab
                      color="secondary"
                      @click="
                        index === 0 ? addLanguage() : removeLanguage(index)
                      "
                    >
                      <!-- <v-icon small>mdi-plus</v-icon> -->
                      <v-icon v-if="index === 0" small>mdi-plus</v-icon>
                      <v-icon v-else small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.number="filter.minYear"
                :label="$t('minimal_age')"
                type="number"
                outlined
                dense
                hide-details
                @input="changedFieldSingle('minYear')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.number="filter.maxYear"
                :label="$t('maximum_age')"
                type="number"
                outlined
                dense
                hide-details
                @input="changedFieldSingle('maxYear')"
              ></v-text-field>
            </v-col>
            <v-col v-if="database" cols="12" md="6">
              <v-currency-field
                v-model.number="filter.minSalary"
                :label="$t('minimal_wage')"
                outlined
                dense
                @input="changedFieldSingle('minSalary')"
              />
            </v-col>
            <v-col v-if="database" cols="12" md="6">
              <v-currency-field
                v-model.number="filter.maxSalary"
                :label="$t('maximum_wage')"
                outlined
                dense
                @input="changedFieldSingle('maxSalary')"
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-autocomplete
                v-model="filter.essentialSkills"
                :items="essentialSkills"
                :label="$t('Skills_competencies')"
                outlined
                dense
                hide-details
                multiple
                :filter="onFilter"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
            <v-btn text class="mx-2" @click="clean()" color="primary">{{
              $t("clean")
            }}</v-btn>
            <v-btn color="primary" @click="apply()">{{ $t("search") }}</v-btn>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import { CANDIDATE_SKILL } from "../graphql/Query.gql";
import { GET_PUBLISHED_VACANCIES_QUERY } from "../modules/vacancy/graphql/Query.resolver";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
export default {
  name: "CandidateFilter",
  mixins: [replaceSpecialCharsMixins],
  props: {
    database: Boolean,
    vacancies: Array,
    vacancyId: String,
  },
  data: () => ({
    Skills: [],
    allVacancies: [],
    formations: [
      {
        course: undefined,
        level: undefined,
      },
    ],
    filter: {
      areasInterest: undefined,
      province: undefined,
      otherSkills: undefined,
      formation: [
        {
          course: undefined,
          level: undefined,
        },
      ],
      ids: undefined,
      languages: [
        {
          name: undefined,
          level: undefined,
        },
      ],
      maxYear: undefined,
      minYear: undefined,
      maxSalary: undefined,
      minSalary: undefined,
      professionalExperience: undefined,
      vacancy: undefined,
      sector: undefined,
      essentialSkills: [],
    },
    vacancy: undefined,
    searchV: "",
    searchA: "",
    searchF: "",
    searchP: "",
    searchFL: "",
    searchLL: "",
    searchL: "",
    searchS: "",
    loading: false,
  }),
  apollo: {
    Skills: {
      query: CANDIDATE_SKILL,
      fetchPolicy: "no-cache",
    },
  },
  watch: {
    searchV(val) {
      if (val) {
        this.querySelections(val);
      } else {
        this.allVacancies = [...this.vacancies];
      }
    },
    vacancyId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.vacancy = newVal;
          
          if (this.vacancies && this.vacancies.length > 0) {
            const selectedVacancy = this.vacancies.find(v => v.id === newVal);
            if (selectedVacancy && selectedVacancy.essentialSkills) {
              this.filter.essentialSkills = [...selectedVacancy.essentialSkills];
            }
          }
        }
      }
    }
  },
  created() {
    if (this.vacancyId) {
      this.vacancy = this.vacancyId;
    }
    
    if (this.$route.query.filter) {
      this.filter = this.$route.query.filter;
    }
    
    if (this.vacancies) {
      this.allVacancies = [...this.vacancies];
    }
  },
  computed: {
    ...mapGetters({
      trainingAreas: "library/trainingAreas",
      provinces: "library/provinces",
    }),
    essentialSkills() {
      if (!this.vacancies || !this.vacancyId) {
        return [];
      }
      
      const selectedVacancy = this.vacancies.find(
        (vacancy) => vacancy.id === this.vacancy);
      
      if (selectedVacancy && selectedVacancy.essentialSkills) {
        return selectedVacancy.essentialSkills;
      }
      
      return [];
    },
    sector() {
      return [
        { key: "private_sec", text: this.$t("private_sec") },
        { key: "NGO", text: this.$t("NGO") },
        { key: "public_sec", text: this.$t("public_sec") },
      ];
    },
    languages() {
      let resultItems = [
        { key: "portuguese", text: this.$t("portuguese") },
        { key: "english", text: this.$t("english") },
        { key: "french", text: this.$t("french") },
        { key: "german", text: this.$t("german") },
        { key: "spanish", text: this.$t("spanish") },
        { key: "barwe", text: "Barwe" },
        { key: "chewa", text: "Chewa" },
        { key: "chiyao", text: "Chiyao" },
        { key: "chopi", text: "Chopi" },
        { key: "chuwabu", text: "Chuwabu" },
        { key: "dema", text: "Dema" },
        { key: "kokola", text: "Kokola" },
        { key: "koti", text: "Koti" },
        { key: "kunda", text: "Kunda" },
        { key: "lolo", text: "Lolo" },
        { key: "lomwe", text: "Lomwe" },
        { key: "maindo", text: "Maindo" },
        { key: "makhuwa", text: "Makhuwa" },
        { key: "makonde", text: "Makonde" },
        { key: "makwe", text: "Makwe" },
        { key: "manyawa", text: "Manyawa" },
        { key: "marenje", text: "Marenje" },
        { key: "mwani", text: "Mwani" },
        { key: "nathembo", text: "Nathembo" },
        { key: "ndau", text: "Ndau" },
        { key: "ngoni", text: "Ngoni" },
        { key: "nsenga", text: "Nsenga" },
        { key: "nyungwe", text: "Nyungwe" },
        { key: "phimbi", text: "Phimbi" },
        { key: "ronga", text: "Ronga" },
        { key: "sena", text: "Sena" },
        { key: "shona", text: "Shona" },
        { key: "swahili", text: "Swahili" },
        { key: "swati", text: "Swati" },
        { key: "takwane", text: "Takwane" },
        { key: "tawara", text: "Tawara" },
        { key: "tewe", text: "Tewe" },
        { key: "tonga", text: "Tonga" },
        { key: "tsonga", text: "Tsonga" },
        { key: "tswa", text: "Tswa" },
        { key: "zulu", text: "Zulu" },
      ];
      if (this.searchL) {
        const text = this.replaceSpecialChars(this.searchL);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.text).includes(text)
        );
      }
      return resultItems;
    },
    levelLanguages() {
      let resultItems = [
        { key: "basic", text: this.$t("basic") },
        { key: "intermediate", text: this.$t("intermediate") },
        { key: "advanced", text: this.$t("advanced") },
      ];

      if (this.searchLL) {
        const text = this.replaceSpecialChars(this.searchLL);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.text).includes(text)
        );
      }
      return resultItems;
    },
    academicLevel() {
      let resultItems = [
        { key: "basic", text: this.$t("basic") },
        { key: "elementary", text: this.$t("elementary") },
        { key: "dedium", text: this.$t("dedium") },
        { key: "bachelor", text: this.$t("bachelor") },
        { key: "graduation", text: this.$t("graduation") },
        { key: "master_degree", text: this.$t("master_degree") },
        { key: "doctorate", text: this.$t("doctorate") },
        { key: "Phd", text: this.$t("Phd") },
      ];
      if (this.searchFL) {
        const text = this.replaceSpecialChars(this.searchFL);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.text).includes(text)
        );
      }
      return resultItems;
    },
    getAreas() {
      let resultItems = [...this.trainingAreas(this.$i18n.locale)];
      if (this.searchA) {
        const text = this.replaceSpecialChars(this.searchA);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.text).includes(text)
        );
        if (resultItems.length === 1) {
          this.trainingAreas(this.$i18n.locale).forEach((element) => {
            resultItems.push(element);
          });
        }
      }
      return resultItems;
    },
    getFormation() {
      let resultItems = [...this.trainingAreas(this.$i18n.locale)];
      if (this.searchF) {
        const text = this.replaceSpecialChars(this.searchF);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.text).includes(text)
        );
        if (resultItems.length === 1) {
          this.trainingAreas(this.$i18n.locale).forEach((element) => {
            resultItems.push(element);
          });
        }
      }
      return resultItems;
    },
    getProvince() {
      let resultItems = [...this.provinces(this.$i18n.locale)];
      if (this.searchP) {
        const text = this.replaceSpecialChars(this.searchP);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.text).includes(text)
        );
        if (resultItems.length === 1) {
          this.provinces(this.$i18n.locale).forEach((element) => {
            resultItems.push(element);
          });
        }
      }
      return resultItems;
    },
    getSkill() {
      let resultItems = [...this.Skills];
      if (this.searchS) {
        const text = this.replaceSpecialChars(this.searchS);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t).includes(text)
        );
      }
      return resultItems;
    },
  },
  methods: {
    onFilter(item, queryText, itemText) {
      return this.replaceSpecialChars(itemText).includes(
        this.replaceSpecialChars(queryText)
      );
    },
    addFormation() {
      this.filter.formation.push({ course: undefined, level: undefined });
    },
    addLanguage() {
      this.filter.languages.push({ name: undefined, level: undefined });
    },
    apply() {
      this.filter.ids = [];
      
      if (this.vacancyId && this.filter.essentialSkills && this.filter.essentialSkills.length > 0) {
        const selectedVacancy = this.vacancies.find(
          (v) => v.id === this.vacancy);
        
        if (selectedVacancy && selectedVacancy.candidacies) {
          // Process in batches to avoid long operations
          const candidacies = selectedVacancy.candidacies;
          const batchSize = 50;
          let processedIds = [];
          
          const processBatch = (startIndex) => {
            const endIndex = Math.min(startIndex + batchSize, candidacies.length);
            
            for (let i = startIndex; i < endIndex; i++) {
              const c = candidacies[i];
              if (c.skills && this.filter.essentialSkills.every((s) => c.skills.includes(s))) {
                processedIds.push(c.candidate);
              }
            }
            
            if (endIndex < candidacies.length) {
              setTimeout(() => processBatch(endIndex), 0);
            } else {
              // All batches processed
              this.filter.ids = processedIds;
              this.$emit("apply", this.filter);
            }
          };
          
          // Start processing the first batch
          processBatch(0);
        } else {
          this.$emit("apply", this.filter);
        }
      } else {
        this.$emit("apply", this.filter);
      }
    },
    changedField(e) {
      if (!e) {
        this.apply();
      } else if (e.length === 0) {
        this.apply();
      }
    },
    changedFieldSingle(field) {
      console.log(field);
      if (!this.filter[field]) {
        this.filter[field] = undefined;
        this.apply();
      }
    },
    clean() {
      this.filter = {
        areasInterest: undefined,
        otherSkills: undefined,
        province: undefined,
        formation: [
          {
            course: undefined,
            level: undefined,
          },
        ],
        ids: [],
        languages: [
          {
            name: undefined,
            level: undefined,
          },
        ],
        maxYear: undefined,
        minYear: undefined,
        maxSalary: undefined,
        minSalary: undefined,
        professionalExperience: undefined,
        sector: undefined,
        essentialSkills: [],
      };
      this.$emit("clean", this.filter);
    },
    removeFormation(index) {
      this.filter.formation.splice(index, 1);
    },
    removeLanguage(index) {
      this.filter.languages.splice(index, 1);
    },
    change() {
      console.log("mudar");
    },
    async searchVacancy(title) {
      if (title.includes("/")) {
        title = title.split("/")[0];
      }
      try {
        return await new Promise((resolve, reject) => {
          this.$apollo
            .query({
              query: GET_PUBLISHED_VACANCIES_QUERY,
              variables: {
                name: title,
                page: 1,
                pageSize: 10,
                vacancyId: undefined,
              },
              // fetchPolicy: "no-cache",
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      } catch (error) {
        console.error(error);
      }
    },
    querySelections(name) {
      this.loading = true;
      this.searchVacancy(name).then((response) => {
        console.log(response.data);
        this.allVacancies = response.data.vacancies;
        // this.$emit("change", this.vacancy);
        this.$emit("search", this.allVacancies);
        this.loading = false;
      });
    },
  },
  mounted() {
    if (this.vacancyId) {
      this.vacancy = this.vacancyId;
      
      if (this.vacancies && this.vacancies.length > 0) {
        const selectedVacancy = this.vacancies.find(v => v.id === this.vacancyId);
        if (selectedVacancy && selectedVacancy.essentialSkills) {
          this.filter.essentialSkills = [...selectedVacancy.essentialSkills];
        }
      }
    }
    
    if (this.$route.query.filter) {
      this.filter = this.$route.query.filter;
    }
    
    if (this.vacancies) {
      this.allVacancies = [...this.vacancies];
    }
  },
};
</script>
